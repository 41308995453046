/*
 * @Author: zhengda.lv
 * @Date: 2022-01-11 10:17:11
 * @LastEditTime: 2022-01-14 14:28:55
 * @LastEditors: zhengda.lv
 * @Description:
 */
// 2286-doucment sample setup

import { fetchGet, fetchPost, fetchPostPic, fetchGetMess } from '../index';
// 获取bu下拉框数据
export const getBuList = (): Promise<any> => {
    return fetchGet('/claimapi/doc/dic/getBuList');
};
// 获取DocType数据
export const getDocTypeList = (): Promise<any> => {
    return fetchGet('/claimapi/doc/dic/getDocTypeList');
};
// 获取DocType数据
export const findAll = (): Promise<any> => {
    return fetchGet('/claimapi/doc/dic/findAll');
};
export const findAllByBu = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/doc/dic/findAllByBu', { params });
};
// 获取文档管理主表对应默认值显示设置数据
export const getDefaultSettingList = (params: any): Promise<any> => {
    return fetchPost(
        '/claimapi/docDicDefaultSetting/getDefaultSettingList',
        params
    );
};
// page数据接口
export const pageList = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/doc/dic/page', params);
};
// edit回显接口
export const docDetail = (docId = ''): Promise<any> => {
    return fetchGet(`/claimapi/doc/dic/detail/${docId}`);
};
// page delete接口
export const docDelete = (docId = ''): Promise<any> => {
    return fetchPostPic(`/claimapi/doc/dic/delete/${docId}`);
};
// deleteFile 数据接口
export const deleteFile = (docId = ''): Promise<any> => {
    return fetchPostPic(`/claimapi/doc/dic/deleteFile/${docId}`);
};
// docEdit接口
export const docEdit = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/doc/dic/edit', params);
};
// docReview接口
export const docReview = (docId = ''): Promise<any> => {
    return fetchGetMess(`/claimapi/doc/dic/review/${docId}`);
};
// docUpload接口
export const docUpload = (params = {}): Promise<any> => {
    return fetchPost('/claimapi/doc/dic/upload', params);
};
