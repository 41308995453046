import { reactive } from "vue";
const usePage = () => {
    // 分页
    const paginationObj = reactive({
        total: 0,
        current: 1,
        size: 20
    })
    interface QueryPageParams {
        current: number;
        size: number;
    }
    interface VoidFuction{
        (): void;
    }
    const pageChange = (page: number, pageSize: number, queryParams: QueryPageParams, getTableDataFun: VoidFuction) => {
        paginationObj.current = page;
        paginationObj.size = pageSize;
        queryParams['current'] =  page > 0 ? page - 1: 0
        queryParams['size'] = paginationObj.size = pageSize
        getTableDataFun()
    };
    const sizeChange = (page: number, pageSize: number, queryParams: QueryPageParams, getTableDataFun: VoidFuction) => {
        paginationObj.current = page;
        paginationObj.size = pageSize;
        queryParams['current'] =  page > 0 ? page - 1: 0
        queryParams['size'] = paginationObj.size = pageSize
        getTableDataFun()
    };
    return {
        paginationObj,
        pageChange,
        sizeChange
    }
}
export default usePage