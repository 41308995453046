
import { defineComponent, ref } from 'vue';
import { docReview } from '@/API/claim/claimDocumentSample'
import { b64toBlob } from "@/utils/claim/b64toBlob"
export default defineComponent({
    emits: ["close-show-visible"],
	props: {
		isShowVisible: {
			type: Boolean,
			required: true,
			default: false
		},
        docId: {
            type: String,
            required: true,
            default: ''
        }
	},
    setup(props, context) {
        // // 设置抽屉显示状态
        const modal = (props as any).isShowVisible;
        const docId = (props as any).docId;
        const comment = ref<string>('')
        const isPdf = ref<boolean>(false)
        const blobUrl = ref<string>('')
        const contentType = "application/pdf";
        const imgBase64 = ref<string>('')
        const imgUrl = ref<any>()
        const close = () => {
            context.emit('close-show-visible', { isShowVisible: false })
        }
        docReview(docId).then(res => {
            comment.value = res.data.comment;
                if (res.data.ext === 'pdf') {
                    isPdf.value = true
                    const blob = b64toBlob(res.data.base64code, contentType);
                    blobUrl.value = URL.createObjectURL(blob);
                } else {
                    isPdf.value = false
                    imgBase64.value = res.data.base64code
                    imgUrl.value = "data:image/jpg;base64," + `${imgBase64.value}`
                }
        })
        return {
            modal,
            close,
            comment,
            isPdf,
            blobUrl,
            imgBase64,
            imgUrl
        };
    },
});
