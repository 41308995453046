
import { defineComponent, reactive, ref, createVNode } from 'vue'
import usePage from "@/hooks/claim/usePage";
import EditSupporting from '../DocumentSample/EditSupporting.vue'
import ViewSample from '../DocumentSample/ViewSample.vue'
import { pageList, docDelete, getBuList } from '@/API/claim/claimDocumentSample'
import { message, Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
type TableDataType = {
    id: string;
    bu: string;
    docNameCn: string;
    docNameEn: string;
    docType: string;
    abbreviation: string;
    filePath: string;
};
type PaginationType = {
    current: number;
    pageSize: number;
};
type FilterType = {
    docType: string;
    filePath: string;
};
type ColumnType = {
    title: string;
    dataIndex?: string;
    filters?: {
        text: string;
        value: string;
        children?: {
        text: string;
        value: string;
        }[];
    }[];
    onFilter?: (value: string, record: TableDataType) => boolean;
    sorter?: (a: TableDataType, b: TableDataType) => number;
    sortDirections?: string[];
    defaultSortOrder?: string;
    filterMultiple?: string[] | boolean;
    slots?: {};
};
export default defineComponent({
    components: {
        EditSupporting,
        ViewSample
    },
    setup() {
        const tableHeight = window.innerHeight - 355
        const { paginationObj, pageChange, sizeChange } = usePage()
        const params = reactive({
            current: 0,
            size: paginationObj.size,
            buList: [],
            docTypeList: [],
            docNameCn: '',
            docNameEn: '',
            sample: '',
        })
        const data = ref<TableDataType[]>([]);
        const getTableData = () => {
            pageList({params}).then(res => {
                data.value = res.content
                paginationObj.total = res.totalElements
            })
        }
        getTableData()
        const docId = ref<string>('')
        // 设置isUploadVisible状态
        const isUploadVisible = ref<boolean>(false)
        const isShowVisible = ref<boolean>(false)
        // 关闭抽屉
        const closeSub = (param: { isUploadVisible: boolean }) => {
            isUploadVisible.value = param.isUploadVisible;
        };
        const closeShow = (param: { isShowVisible: boolean }) => {
            isShowVisible.value = param.isShowVisible;
        };
        const editHandle = (id: string) => {
            isUploadVisible.value = true
            docId.value = id
        }
        const viewHandle = (id: string) => {
            isShowVisible.value = true
            docId.value = id
        }
        const handleChange = (value: []) => {
            params.buList = value
        };
        const BUList = ref([])
        getBuList().then(res => {
            BUList.value = res
        })
        // 查询表格数据
        const columns: ColumnType[] = [
    {
        title: 'BU',
        dataIndex: 'bu',
    },
    {
        title: 'Document Name(CN)',
        dataIndex: 'docNameCn',
    },
    {
        title: 'Document Name(EN)',
        dataIndex: 'docNameEn',
    },
    {
        title: 'File Type',
        dataIndex: 'docType',
        filters: [
            {
                text: 'pdf',
                value: 'pdf',
            },
            {
                text: 'jpg',
                value: 'jpg',
            },
        ],
    },
    {
        title: 'Abbreviation',
        dataIndex: 'abbreviation',
    },
    {
        title: 'Sample',
        dataIndex: 'filePath',
        filters: [
            {
                text: 'Y',
                value: 'Y',
            },
            {
                text: 'N',
                value: 'N',
            },
        ],
        filterMultiple: false,
        slots: {
            customRender: 'sample',
        },
    },
    {
        title: 'Operation',
        slots: { customRender: 'operation' }
    },
];
        // 删除某条数据
        const onDelete = (id: string) => {
            Modal.confirm({
                title: 'Tips',
                content: 'Do you confirm to delete current Document Info?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: 'Confirm',
                centered: true,
                onOk() {
                    const docId = id
                    docDelete(docId).then(res => {
                        if (res.code === '0') {
                            message.success(res.message)
                            getTableData()
                        }
                    })
                }
            })
        }
        const resetHandle = () => {
            params.buList = []
            params.docNameCn = ''
            params.docNameEn = ''
        }
        const filtersParams = ref()
        const onChange = (pagination: PaginationType, filters: FilterType[]) => {
            filtersParams.value = filters
            params.docTypeList = filtersParams.value?.docType ? filtersParams.value?.docType : []
            params.sample = filtersParams.value?.filePath ? filtersParams.value?.filePath[0] : ''
            getTableData()
        };
        return {
            params,
            handleChange,
            docId,
            BUList,
            data,
            columns,
            onChange,
            paginationObj,
            pageChange,
            sizeChange,
            isUploadVisible,
            isShowVisible,
            closeSub,
            closeShow,
            editHandle,
            viewHandle,
            resetHandle,
            onDelete,
            getTableData,
            tableHeight
        };
    },
});
