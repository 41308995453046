
import axios from 'axios'
import { defineComponent, reactive, ref, createVNode } from 'vue'
import { CheckCircleOutlined } from '@ant-design/icons-vue'
import { PayoutRoundListData } from '@/views/Claim/RT/types'
import { FileItem } from '@/API/types';
import { b64toBlob } from "@/utils/claim/b64toBlob"
import { message, Modal } from 'ant-design-vue';
import { docDetail, deleteFile, docEdit } from '@/API/claim/claimDocumentSample'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default defineComponent({
    components: {
        CheckCircleOutlined
    },
    emits: ["close-sub-visible", "get-table-data"],
	props: {
		isUploadVisible: {
			type: Boolean,
			required: true,
			default: false
		},
        docId: {
            type: String,
            required: true,
            default: ''
        }
	},
    setup(props, context) {
        const abbrevErrorPrompt = ref<string>('')
        const commentErrorPrompt = ref<string>('')
        const docId = (props as any).docId;
        const checkCommentVal = ref<any>(true)
        const AbbreviationVal = ref<any>(true)
        // 文件展示框是否显示，它显示，上传功能就隐藏
        const hasFileShow = ref<boolean>(true)
        const isPdf = ref<boolean>(false)
        const contentType = "application/pdf";
        const imgBase64 = ref<string>('')
        const imgUrl = ref<any>()
        const blobUrl = ref<string>('')
        const editData = reactive<any>({
            id: docId,
            bu: '',
            abbreviation: '',
            docNameCn: '',
            docNameEn: '',
            docType: '',
            comment: '',
            filePath: '',
            fileType:'',
            fileSize:'',
            documentId:''
        })
        // 调用edit页面回显接口
        docDetail(docId).then(res => {
            const {bu, abbreviation, docNameCn, docNameEn, docType, comment} = res
            Object.assign(editData, {bu, abbreviation, docNameCn, docNameEn, docType, comment})
            if (res.filePath !== '') {
                hasFileShow.value = true
                editData.filePath = res.filePath;
                if (res.ext === 'pdf') {
                    isPdf.value = true
                    const blob = b64toBlob(res.base64code, contentType);
                    blobUrl.value = URL.createObjectURL(blob);
                } else {
                    isPdf.value = false
                    imgBase64.value = res.base64code
                    imgUrl.value = "data:image/jpg;base64," + `${imgBase64.value}`
                }
            } else {
                hasFileShow.value = false
            }
        })
        // 删除回显文件接口
        const deleteHandle = () => {

            Modal.confirm({
                title: 'Tips',
                content: 'Do you confirm to delete current file?',
                icon: createVNode(ExclamationCircleOutlined),
                okText: 'Confirm',
                centered: true,
                onOk() {
                    deleteFile(docId).then((res) => {
                        if (res.code === '0') {
                            editData.filePath = ''
                            message.success(res.message)
                            hasFileShow.value = false
                            context.emit('get-table-data')
                        }
                    })
                }
            })
        }
        // 调用
        const isBeforeUpload = ref<boolean>(true)
        // 进度条
        const isShowUploadBtn = ref<boolean>(true)
        const curUploadFile = ref<object>({});
        const curPercent = ref<number>(0)
        const uploadFileSize = ref<string>('')
        const completeTips = ref<boolean>(false)
        // 上传功能出错后下载模态窗口
        const loading = ref<boolean>(false);
        const downloadModalVisible = ref<boolean>(false)
        const downloadTipsText = ref<string>('')
        const roundVal = ref()
        // 文件上传
        const isClickAbled = ref<boolean>(true)
        const uploadComplete = ref<boolean>(true);
        const formData = new FormData();
        const emptyVisible = ref<boolean>(false)
        const emptyTipsText = ref<string>('')
        // // 设置抽屉显示状态
        const modal = (props as any).isUploadVisible;

        const close = () => {
            context.emit('close-sub-visible', { isUploadVisible: false })
        }
        const roundList = ref<PayoutRoundListData[]>([])
                //获得输入框中字符长度
        const  getLength=(val: string)=> {
            const str = new String(val);
            let bytesCount = 0;
            for (let i = 0 ,n = str.length; i < n; i++) {
                const c = str.charCodeAt(i);
                if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) {
                    bytesCount += 1;
                } else {
                    bytesCount += 2;
                }
            }
            return bytesCount;
        }

        //Comment的失去焦点的校验
        const CommentBlur =()=>{
            if ( getLength(editData.comment)>200){
                // message.error("Exceed Limited Word")
                commentErrorPrompt.value = "Exceed Limited Word"
                return false
            } else {
                commentErrorPrompt.value = ""
                return true
            }
        }

        //Abbreviation的失去焦点的校验
        const AbbreviationBlur = ()=>{
            if (getLength(editData.abbreviation)>50){
                // message.error("Exceed Limited Word")
                abbrevErrorPrompt.value = "Exceed Limited Word"
                return false
            } else {
                abbrevErrorPrompt.value = ""
            }

            if (!/^[A-Z]*$/.test(editData.abbreviation)){
                // message.error("Only uppercase letters are allowed")
                abbrevErrorPrompt.value = "Only uppercase letters are allowed"
                return false
            } else {
                abbrevErrorPrompt.value = ""
            }

            if (!(getLength(editData.abbreviation)>50) && !(!/^[A-Z]*$/.test(editData.abbreviation))) {
                return true
            } else {
                abbrevErrorPrompt.value = ""
            }
        }
        // 上传前
        const beforeUpload = (file: FileItem) => {
            const isJpg = file.type === 'image/jpeg';
            const isPdf = file.type === 'application/pdf';
            if (!isJpg && !isPdf) {
                message.error('jpg/pdf format files can be uploaded!')
            }
            return isJpg || isPdf
        }
        const uploadRequest = (source: any) => {
            if (formData.has('document')){
                formData.delete('document')
            }
            formData.append('document', source.file);
            curUploadFile.value = source.file;
            uploadFileSize.value = source.file.size / 1024 > 1024 ? `${(source.file.size / 1024 / 1024).toFixed(1)} MB` : `${Math.floor(source.file.size / 1024)} KB`
            isBeforeUpload.value = false
        }
        // 上传文件
        const uploadFile = (source: any) => {
            isClickAbled.value = false
            const params = {
                document: formData
            }
            axios({
                method: 'post',
                data: formData,
                url: '/claimapi/doc/dic/upload',
                params: params
            }).then((res) => {
                if (res.data.code === '0') {
                    debugger
                    editData.filePath = res.data.data.docPath;
                    editData.fileType = res.data.data.docType;
                    editData.fileSize  = res.data.data.fileSize;
                    editData.documentId  = res.data.data.id;
                    isShowUploadBtn.value = false
                    curPercent.value = 100
                    completeTips.value = true
                    isClickAbled.value = true
                    message.success(res.data.message)
                    context.emit('get-table-data')
                } else {
                    message.error(res.data.message)
                }
            })
        }
        // 确认编辑完成接口
        const upDateHandle = () => {
            checkCommentVal.value = CommentBlur()
            AbbreviationVal.value = AbbreviationBlur()
            if (checkCommentVal.value && AbbreviationVal.value) {

                const params = editData
                docEdit(params).then(res => {
                    if (res.code === '0') {
                        message.success(res.message)
                        context.emit('get-table-data')
                        close()
                    }
                })
            }
        }
        const showUploadHandle = () => {
            isBeforeUpload.value = true
            isShowUploadBtn.value = true
            curPercent.value = 0
            completeTips.value = false
        }

        return {
            checkCommentVal,
            AbbreviationVal,
            CommentBlur,
            AbbreviationBlur,
            editData,
            hasFileShow,
            isPdf,
            imgUrl,
            blobUrl,
            downloadModalVisible,
            downloadTipsText,
            deleteHandle,
            roundVal,
            modal,
            close,
            roundList,
            uploadFile,
            beforeUpload,
            uploadComplete,
            isBeforeUpload,
            isShowUploadBtn,
            uploadRequest,
            curPercent,
            curUploadFile,
            uploadFileSize,
            showUploadHandle,
            upDateHandle,
            completeTips,
            isClickAbled,
            emptyVisible,
            emptyTipsText,
            abbrevErrorPrompt,
            commentErrorPrompt
        }
    },
})
